import React, { useState, useEffect } from 'react';

import { useMsal } from '@azure/msal-react';
import { fetchDataAuthenticated } from '../../../utilities/HttpUtils';

import useAlert from '../../Alerts/useAlert';

import '../../DataGrid.css';
import '../../../App.css';

// Styling 
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function ComponentGroupLookupForm(props) {

    const _apiUrl = process.env.REACT_APP_API_URL;

    const [siteDetails, setSiteDetails] = useState([]);

    const defaultRow = {
        componentGroupLookupId: 0,
        site: "",
        product: "",
        language: "",
        customer: "",
        componentGroupName: "",
        description: "",
        modifiedBy: "",
        modifiedOn: ""

    };

    const { accounts } = useMsal();

    const username = accounts[0] && accounts[0].username;

    const { setAlert } = useAlert();

    const GetSiteDetails = async () => {

        await fetchDataAuthenticated(_apiUrl + "UniqueGomSiteDetails")
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then(data => {
                setSiteDetails(data);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                console.error(error);
            });

    };

    useEffect(() => {
        GetSiteDetails();
    }, []);

    const AddComponentGroupLookupflag = (props && props.SelectedValueTochild && props.SelectedValueTochild != "") ? false : true;
    const SelectedValue = (props && props.SelectedValueTochild && props.SelectedValueTochild != "") ? JSON.parse(props.SelectedValueTochild) : defaultRow;

    const [CompValue, SelectedCompValue] = React.useState(SelectedValue);

    const CloseDialoginParent = (flag) => {
        props.CloseDialog(flag);
    };

    const [site, setSite] = React.useState(SelectedValue.site);
    const handleChangeSite = (event) => {
        setSite(event.target.value);
        CompValue.site = event.target.value;
        SelectedCompValue(CompValue);
    };

    const handleTextFieldChange = (event) => {
        CompValue[event.target.id] = event.target.value;
        SelectedCompValue(CompValue);
    };

    const saveComponentValues = async () => {
        const saveBtn = document.getElementById('btnSave');
        const serviceUrl = _apiUrl + (AddComponentGroupLookupflag ? "AddComponentGroupLookup" : "UpdateComponentGroupLookup")

        if (CompValue.site == null || CompValue.site === undefined || CompValue.site === "") {
            setAlert("primary", "Validation", "Please select Site");
            return false;
        }
        if (CompValue.product == null || CompValue.product === undefined || CompValue.product === "") {
            setAlert("primary", "Validation", "Please enter Product");
            return false;
        }
        if (CompValue.language == null || CompValue.language === undefined || CompValue.language === "") {
            setAlert("primary", "Validation", "Please enter Language");
            return false;
        }
        if (CompValue.customer == null || CompValue.customer === undefined || CompValue.customer === "") {
            setAlert("primary", "Validation", "Please enter Customer");
            return false;
        }
        if (CompValue.componentGroupName == null || CompValue.componentGroupName === undefined || CompValue.componentGroupName === "") {
            setAlert("primary", "Validation", "Please enter Component Group Name");
            return false;
        }
        saveBtn.disabled = true;
        CompValue.modifiedBy = username;

        await fetchDataAuthenticated(serviceUrl, "POST", JSON.stringify(CompValue))
            .then(response => {
                if (response.ok) {
                    saveBtn.disabled = false;
                    return response.json();
                }

                throw new Error('Something went wrong.');
            })
            .then((response) => {
                if (response.result)
                    CloseDialoginParent(true);

                setAlert("success", "Success", response.message);
                saveBtn.disabled = false;
            })
            .catch(error => {
                console.error(error);
                setAlert("danger", "Error", error.message);
                saveBtn.disabled = false;
            });
    };

    return (
        <DialogContent>
            <div className='container-fluid csDialogbottom'>
                <div className='row mt-2'>
                    <div className='col-md-4'>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="lblsiteId" className='required'>Site</InputLabel>
                                <Select
                                    labelId="lblSiteId"
                                    id="isiteId"
                                    value={site}
                                    label="Site *"
                                    onChange={handleChangeSite}
                                >
                                    <MenuItem value="">Select Site</MenuItem>
                                    {siteDetails.map((sitedt, index) => {
                                        return (
                                            <MenuItem value={sitedt.siteName} key={"lblsiteId" + sitedt.siteId + index}>{sitedt.siteName}</MenuItem>
                                        );
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="product"
                                label="Product"
                                defaultValue={CompValue.product}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 20 }}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="language"
                                label="Language"
                                defaultValue={CompValue.language}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 2 }}
                            />
                        </Box>
                    </div>
                </div>
                <div className='clearfix'></div>
                <div className='row mt-2'>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="customer"
                                label="Customer"
                                defaultValue={CompValue.customer}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 20 }}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="componentGroupName"
                                label="Component Group Name"
                                defaultValue={CompValue.componentGroupName}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 20 }}
                            />
                        </Box>
                    </div>

                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField
                                id="description"
                                label="Description"
                                defaultValue={CompValue.description}
                                onChange={handleTextFieldChange}
                            />
                        </Box>
                    </div>
                </div>
                <div className='clearfix'></div>

            </div>
            <div className='clearfix'></div>
            <div className='row'>
                <div className='col-md-9'></div>
                <div className='col-md-3 row'>
                    <div className='col-md-6'>
                        <button type="button" className="btn btn-outline-secondary" onClick={CloseDialoginParent}>Cancel</button>
                    </div>
                    <div className='col-md-6'>
                        {/* TODO: Disable Save if the required fields are not set */}
                        <button type="button" id="btnSave" className="btn btn-outline-primary" onClick={saveComponentValues}>Save</button>
                    </div>
                </div>
            </div>
        </DialogContent>

    );
}

export default ComponentGroupLookupForm;